import ReactDOM from 'react-dom';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import CacheBuster from 'react-cache-buster';
import { ConfigProvider } from 'antd';
import antdLocaleDE from 'antd/es/locale/de_DE';
import antdlocaleEN from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

import { TrackerProvider } from 'services/providers';

import 'antd/dist/reset.css';
import '@adyen/adyen-web/dist/adyen.css';
import './index.css';
import './font.css';
import './ant.css';
import './adyen.css';

import { getLanguage } from 'utils';
import { LanguageContainer } from 'containers';
import { messages } from 'messages';
import { DarkLoading, LoadingWrapper } from 'components/general';

import packageInfo from '../package.json';

const App = lazy(() =>
  import('./App.react').then(module => ({
    default: module.App,
  }))
);
const ApplicationErrorPage = lazy(() =>
  import('./components/ApplicationErrorPage').then(module => ({
    default: module.ApplicationErrorPage,
  }))
);

const queryClient = new QueryClient();
const { version } = packageInfo;

// Set default locale to match the app's locale
const initialLocale = getLanguage();
dayjs.locale(initialLocale); // Set initial locale

const AppWithLanguage = () => {
  const { currentLocale } = LanguageContainer.useContainer();

  useEffect(() => {
    dayjs.locale(currentLocale);
  }, [currentLocale]);

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      wrapRichTextChunksInFragment
    >
      <TrackerProvider>
        <HelmetProvider>
          <Helmet>
            <meta name="apple-itunes-app" content="app-id=1531742708" />
          </Helmet>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider
                locale={currentLocale === 'de' ? antdLocaleDE : antdlocaleEN}
              >
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <DarkLoading />
                    </LoadingWrapper>
                  }
                >
                  <ApplicationErrorPage>
                    <App />
                  </ApplicationErrorPage>
                </Suspense>
              </ConfigProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </TrackerProvider>
    </IntlProvider>
  );
};

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isVerboseMode={false}
    loadingComponent={
      <LoadingWrapper>
        <DarkLoading />
      </LoadingWrapper>
    }
  >
    <LanguageContainer.Provider initialState={getLanguage()}>
      <AppWithLanguage />
    </LanguageContainer.Provider>
  </CacheBuster>,
  document.querySelector('#root')
);
