export const GOOGLE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=de.culture4life.luca';

export const APPLE_DOWNLOAD_LINK =
  'https://apps.apple.com/de/app/luca-app/id1531742708';

export const GENERAL_DOWNLOAD_LINK =
  'https://www.luca-app.de/download-link-redirect';

export const HELLO_EMAIL = 'hello@luca-app.de';

export const REPORT_FRAUD_EMAIL = 'abuse@luca-app.de';

export const IMPRINT = 'https://www.luca-business.com/legal/impressum';

export const PRIVACY_LINK =
  'https://www.luca-business.com/legal/ds/datenschutzerklarung-luca-app';

export const TERMS_CONDITIONS_LINK =
  'https://www.luca-business.com/legal/nb/nutzungsbedingungen';

export const INSTALL_REDIRECT_LINK = 'https://app.luca-app.de/webapp/install';

export const TERMS_CONDITIONS_LINK_EN =
  'https://www.luca-business.com/en/legal/nb/terms-of-use';

export const PRIVACY_LINK_EN =
  'https://www.luca-business.com/en/legal/ds/app-privacy-policy';

export const IMPRINT_EN = 'https://www.luca-business.com/en/legal/imprint';
